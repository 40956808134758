  <template>
  	<Layout>
  		<!-- <PageHeader :title="title" :items="items" /> -->
  		<div class="row">
  			<div class="col-12">
  				<ul class="nav nav-tabs pt-3" style="border: 0;">
  					<li class="active"><a href="#" class="btn btn-sm btn-head text-white">CASH ADVANCE</a></li>
  				</ul>
  				<div class="card new-card">
  					<div class="card-body">
  						<!-- <div class="row">

            </div> -->
  						<BuyingList :show-action="true"></BuyingList>
  					</div>
  				</div>
  			</div>
  		</div>
  	</Layout>
  </template>

  <script>
  	import appConfig from '@src/app.config'
  	import Layout from '@layouts/main'
  	// import PageHeader from '@components/page-header'
  	import BuyingList from '@components/MultiBuyingDownPayment/BuyingList.vue'

  	// import { mapActions } from 'vuex'

  	export default {
  		page: {
  			title: 'Multi Buying Down Payment',
  			meta: [{
  				name: 'description',
  				content: appConfig.description,
  			}, ],
  		},
  		components: {
  			BuyingList,
  			Layout,
  			// PageHeader,
  		},
  		data() {
  			return {
  				title: 'Buying List',
  				items: [{
  						text: 'Master',
  						href: '/',
  					},
  					{
  						text: 'BuyingList',
  						href: '/BuyingList',
  					},
  					{
  						text: 'List',
  						active: true,
  					},
  				],
  			}
  		},
  	}

  </script>
